import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {AppInterface, Bookmark} from "../../../interfaces/app.interface";

@Component({
  selector: 'bookmark-icon',
  standalone: true,
  imports: [],
  templateUrl: './bookmark-icon.component.html',
  styleUrl: './bookmark-icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookmarkIconComponent {
  @Input({required: true}) bookmark!: Bookmark | AppInterface;

}
